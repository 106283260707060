import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import styles from "./FormComponent.module.css";
import { db } from "../firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  writeBatch,
} from "firebase/firestore";
import imageCompression from "browser-image-compression";

type MessageEntry = {
  id: string;
  title: string;
  content: string;
  created_at: Date;
  isToday: boolean;
  read: boolean;
  photo_url?: string; // 新增 photo_url
};

interface FormComponentProps {
  title?: string;
}

export interface FormComponentHandles {
  init: () => void;
}

const FormComponent = forwardRef<FormComponentHandles, FormComponentProps>(
  ({ title }, reactRef) => {
    const [email, setEmail] = useState<string>("");
    const [nickName, setNickName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [responseVisible, setResponseVisible] = useState<boolean>(false);
    const [fakeResponseVisible, setFakeResponseVisible] =
      useState<boolean>(false);
    const [dailyLimit] = useState<number>(4);
    const [dailyLimitLeft, setDailyLimitLeft] = useState<number>(dailyLimit);
    const [limitReached, setLimitReached] = useState<boolean>(false);
    const [replyContent, setReplyContent] = useState<string>("");
    const [messageEntries, setMessageEntries] = useState<MessageEntry[]>([]);
    const [todayMsgs, setTodayMsgs] = useState<number>(0);
    const [todayMsgsArray, setTodayMsgsArray] = useState<string[]>([]);
    const [notTodayMsgsArray, setNotTodayMsgsArray] = useState<string[]>([]);
    const [unsubscribe, setUnsubscribe] = useState<(() => void) | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [imageFileUploadProgress, setImageFileUploadProgress] =
      useState<number>(0);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null); // 用於顯示放大的圖片

    const fileInputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(reactRef, () => ({
      init,
    }));

    const backendBasicApi =
      process.env.NODE_ENV === "development"
        ? "http://localhost:5001/zodiacinsightsexplorer/us-central1/api"
        : "https://us-central1-zodiacinsightsexplorer.cloudfunctions.net/api";

    const init = async () => {
      setResponseVisible(false);
      setFakeResponseVisible(false);
      setLoading(false);
      setEmail("");
      setNickName("");
    };

    const submitForm = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(db, "config", "user");
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const firestoreEmail = userDoc.data()?.email;

          if (email === firestoreEmail) {
            // Email 匹配，繼續後續操作

            // 獲取當天日期，格式為 YYYYMMDD
            const today = new Date();
            const date = `${today.getFullYear()}${
              today.getMonth() + 1
            }${today.getDate()}`;

            // 訂閱 messages 集合
            const messagesRef = collection(db, "messages");
            const messagesQuery = query(
              messagesRef,
              where("display", "==", true),
              orderBy("created_at", "asc")
            );

            const unsubscribeFunc = onSnapshot(
              messagesQuery,
              async (snapshot) => {
                console.log("Received query snapshot", snapshot);
                if (snapshot.empty) {
                  // 如果沒有訊息，獲取問候語
                  const sayHelloDocRef = doc(db, "sayHello", date);
                  const sayHelloDoc = await getDoc(sayHelloDocRef);

                  setMessageEntries([
                    {
                      id: "sayHello",
                      title: sayHelloDoc.data()?.text || "Hello",
                      content: "還沒有任何訊息 :)",
                      created_at: new Date(),
                      isToday: true,
                      read: false,
                    },
                  ]);
                } else {
                  const messagesArray: MessageEntry[] = [];
                  let todayMessagesCount = 0;
                  const todayMsgIds: string[] = [];
                  const notTodayMsgIds: string[] = [];

                  snapshot.forEach((doc) => {
                    const messageData = doc.data();
                    const messageDate = messageData.created_at.toDate();
                    const messageDateStr = `${messageDate.getFullYear()}${
                      messageDate.getMonth() + 1
                    }${messageDate.getDate()}`;
                    const isToday = messageDateStr === date;

                    if (!messageData.read || isToday) {
                      messagesArray.push({
                        id: doc.id,
                        title: messageData.title,
                        content: messageData.content,
                        created_at: messageDate,
                        isToday,
                        read: messageData.read,
                        photo_url: messageData.photo_url, // 包含 photo_url
                      });
                    }

                    if (isToday) {
                      if (messageData.title !== "佑佑透過 Whatsapp 留言訊息") {
                        todayMessagesCount++;
                      }
                      todayMsgIds.push(doc.id);
                    } else {
                      notTodayMsgIds.push(doc.id);
                    }
                  });

                  setMessageEntries(messagesArray);
                  setTodayMsgs(todayMessagesCount);
                  setTodayMsgsArray(todayMsgIds);
                  setNotTodayMsgsArray(notTodayMsgIds);
                  setDailyLimitLeft(dailyLimit - todayMessagesCount);

                  // 更新是否達到每日上限的狀態
                  setLimitReached(todayMessagesCount >= dailyLimit);
                }
              }
            );

            // 保存取消訂閱的函數，以便在需要時使用
            setUnsubscribe(() => unsubscribeFunc);

            setResponseVisible(true);
          } else {
            setFakeResponseVisible(true);
          }
        } else {
          console.error("No such document!");
          setFakeResponseVisible(true);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setFakeResponseVisible(true);
      }
      setLoading(false);
    };

    const fetchToBackend = async (
      content: string,
      title: string,
      photo_url: string | null
    ) => {
      try {
        const response = await fetch(backendBasicApi + "/addMsg", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            content,
            title,
            photo_url, // Include imageUrl in the request body
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.text();
        console.log("Success:", data);
      } catch (error) {
        console.error("Error uploading message:", error);
      }
    };

    const handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        try {
          // 壓縮圖片
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.5, // 最大檔案大小（MB）
            maxWidthOrHeight: 800, // 限制最大寬度或高度
            useWebWorker: true, // 使用 Web Worker 加速處理
          });

          // 開始上傳壓縮後的圖片
          const storage = getStorage();
          const storageRef = ref(storage, `images/${compressedFile.name}`);
          const uploadTask = uploadBytesResumable(storageRef, compressedFile);

          setImageFileUploadProgress(0);
          setImageLoading(true);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = parseFloat(
                (
                  (snapshot.bytesTransferred / snapshot.totalBytes) *
                  100
                ).toFixed(2)
              );
              setImageFileUploadProgress(progress);
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error("Upload failed:", error);
              setImageLoading(false);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setImageUrl(downloadURL);
                setImageLoading(false);
              });
            }
          );
        } catch (error) {
          console.error("Error compressing the image:", error);
        }
      }
    };

    const submitReply = async () => {
      setLoading(true);

      await fetchToBackend(replyContent, nickName || "Reply", imageUrl);

      // 更新 Firestore 中的訊息狀態
      if (nickName !== "Keyo") {
        const batch = writeBatch(db);

        todayMsgsArray.forEach((msgId) => {
          const msgRef = doc(db, "messages", msgId);
          batch.update(msgRef, { read: true, read_at: new Date() });
        });

        notTodayMsgsArray.forEach((msgId) => {
          const msgRef = doc(db, "messages", msgId);
          batch.update(msgRef, {
            display: false,
            read: true,
            read_at: new Date(),
          });
        });

        await batch.commit();
      }

      // 重置狀態
      setReplyContent("");
      setImageUrl(null);
      setImageFileUploadProgress(0);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      setLoading(false);
    };

    useEffect(() => {
      // 清理訂閱
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }, [unsubscribe]);

    const formatDate = (date: Date): string => {
      return date.toLocaleDateString("zh-TW", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
    };

    return (
      <div className={styles.container}>
        {loading && (
          <div className={styles.loadingWrapper}>
            <div className={styles.loadingSpinner}></div>
            <p>正在處理您的請求，請稍候...</p>
          </div>
        )}
        {!loading && !responseVisible && !fakeResponseVisible && (
          <div className={styles.formCard}>
            <h3 className={styles.formTitle}>
              {title ?? "請填寫您的資訊以獲取更多星座運勢"}
            </h3>
            <div className={styles.formGroup}>
              <input
                className={styles.formInput}
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className={styles.formInput}
                type="text"
                name="nickName"
                placeholder="暱稱"
                value={nickName}
                onChange={(e) => setNickName(e.target.value)}
              />
            </div>
            <button className={styles.submitButton} onClick={submitForm}>
              提交
            </button>
          </div>
        )}
        {responseVisible && !loading && (
          <div className={styles.messageContainer}>
            <div className={styles.messageList}>
              {messageEntries.map((message) => (
                <div key={message.id} className={styles.messageCard}>
                  <h4 className={styles.messageTitle}>{message.title}</h4>
                  <p className={styles.messageContent}>
                    {message.content.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                  {message.photo_url && (
                    <img
                      src={message.photo_url}
                      alt="Uploaded"
                      className={styles.messageImage}
                      onClick={() =>
                        setSelectedImage(message?.photo_url ?? null)
                      }
                    />
                  )}
                  <div className={styles.messageFooter}>
                    <span className={styles.messageTime}>
                      撰寫時間: {formatDate(message.created_at)}
                    </span>
                    {!message.isToday && (
                      <span className={styles.unreadBadge}>未讀訊息</span>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.divider}></div>

            {!limitReached ? (
              <div className={styles.replySection}>
                <textarea
                  className={styles.replyInput}
                  name="replyContent"
                  placeholder="輸入您的回覆..."
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                ></textarea>
                <input
                  type="file"
                  accept="image/*"
                  className={styles.fileInput}
                  name="fileInput"
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                />
                {imageFileUploadProgress > 0 &&
                  imageFileUploadProgress < 100 && (
                    <div className={styles.fileUploadProgress}>
                      上傳進度：{imageFileUploadProgress}%
                    </div>
                  )}

                {imageLoading && (
                  <div className={styles.imageLoading}>圖片加載中...</div>
                )}

                {imageUrl && !imageLoading && (
                  <div className={styles.imagePreviewWrapper}>
                    <img
                      src={imageUrl}
                      alt="Preview"
                      className={styles.imagePreview}
                      onLoad={() => setImageLoading(false)}
                    />
                  </div>
                )}
                <button className={styles.replyButton} onClick={submitReply}>
                  發送回覆
                </button>
                <div className={styles.dailyLimitLeft}>
                  今日可留言訊息剩餘{dailyLimitLeft}則
                </div>
              </div>
            ) : (
              <div className={styles.limitMessage}>
                每天最多{dailyLimit}則訊息，本日已達上限
              </div>
            )}
          </div>
        )}

        {fakeResponseVisible && (
          <div className={styles.successCard}>
            <h3 className={styles.successTitle}>感謝您的參與！</h3>
            <p className={styles.successMessage}>
              我們已收到您的信息，稍後將提供更多資訊。
            </p>
          </div>
        )}

        {selectedImage && (
          <div className={styles.modal} onClick={() => setSelectedImage(null)}>
            <span className={styles.close}>&times;</span>
            <img
              className={styles.modalContent}
              src={selectedImage}
              alt="Enlarged"
            />
          </div>
        )}
      </div>
    );
  }
);

export default FormComponent;
