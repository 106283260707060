import React, { useEffect, useState } from "react";
import "./ZodiacingSightsExplorer.css";
import FormComponent from "../FormComponent/FormComponent";

interface ZodiacingSightsExplorerProps {
  displayForm: boolean;
  setDisplayForm: (value: boolean) => void;
}

const ZodiacingSightsExplorer: React.FC<ZodiacingSightsExplorerProps> = ({ displayForm, setDisplayForm }) => {
  const [time, setTime] = useState(300);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 1) {
          clearInterval(interval);
          window.location.reload();
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const showForm = (answer: string) => {
    if (answer === "獅子座") {
      setDisplayForm(true);
    } else {
      alert("答案錯誤，請再試一次！");
    }
  };

  return (
    <div className="App">
      <header className="header">
        <h1>星座運勢</h1>
      </header>
      <div className="content">
        <p>探索您的星座運勢，了解未來一週的運氣。</p>
        <p>我們提供詳細的星座分析，幫助您更好地規劃生活。</p>
        <p>請回答以下問題以獲取更多資訊。</p>
        <p className="timer">
          您有{Math.floor(time / 60)}分{time % 60}秒來回答下列問題。
        </p>
        <div className="question">
          <p>問題: 哪個星座的日期是從7月23日到8月22日？</p>
          <button className="button" onClick={() => showForm("雙子座")}>
            雙子座
          </button>
          <button className="button" onClick={() => showForm("獅子座")}>
            獅子座
          </button>
          <button className="button" onClick={() => showForm("天秤座")}>
            天秤座
          </button>
        </div>
        {displayForm && <FormComponent />}
      </div>
    </div>
  );
};

export default ZodiacingSightsExplorer;
