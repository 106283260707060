import React, { useState, useEffect, useRef } from "react";
import "./CurrencyExchangeTool.css";
import FormComponent, {
  FormComponentHandles,
} from "../FormComponent/FormComponent";

interface CurrencyExchangeToolProps {
  displayForm: boolean;
  setDisplayForm: (value: boolean) => void;
}

interface CurrencyRate {
  currency: string;
  buy: {
    cash: number | null;
    spot: number | null;
    forward: {
      days10: number | null;
      days30: number | null;
      days60: number | null;
      days90: number | null;
      days120: number | null;
      days150: number | null;
      days180: number | null;
    };
  };
  sell: {
    cash: number | null;
    spot: number | null;
    forward: {
      days10: number | null;
      days30: number | null;
      days60: number | null;
      days90: number | null;
      days120: number | null;
      days150: number | null;
      days180: number | null;
    };
  };
}

interface CurrencyExchangeResponse {
  publishTime: {
    date: string;
    time: string;
    timestamp: string;
  };
  rates: CurrencyRate[];
}

const CurrencyExchangeTool: React.FC<CurrencyExchangeToolProps> = ({
  displayForm,
  setDisplayForm,
}) => {
  const [amount, setAmount] = useState<number>(1);
  const [fromCurrency, setFromCurrency] = useState<string>("EUR");
  const [toCurrency, setToCurrency] = useState<string>("TWD");
  const [exchangeRate, setExchangeRate] = useState<number | null>(null);
  const [buyRate, setBuyRate] = useState<number | null>(null);
  const [sellRate, setSellRate] = useState<number | null>(null);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [result, setResult] = useState<string | null>("");
  const formRef = useRef<FormComponentHandles>(null);

  const backendBasicApi =
    process.env.NODE_ENV === "development"
      ? "http://localhost:5001/zodiacinsightsexplorer/us-central1/api"
      : "https://us-central1-zodiacinsightsexplorer.cloudfunctions.net/api";

  const handleInit = () => {
    if (formRef.current) {
      formRef.current.init();
    }
  };

  const fetchExchangeRate = async () => {
    const today = new Date().toISOString().split("T")[0];
    const yesterday = new Date(Date.now() - 86400000)
      .toISOString()
      .split("T")[0];
    console.log(yesterday);

    let response = await fetch(backendBasicApi + "/getCurrencyRate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ date: today }),
    });
    if (response.status !== 200) {
      response = await fetch(backendBasicApi + "/getCurrencyRate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date: yesterday }),
      });
    }
    const data: CurrencyExchangeResponse = await response.json();
    const rate = data.rates.find(
      (rate: { currency: string }) => rate.currency === "EUR"
    );
    const buyRate = rate?.buy?.spot || 0;
    const sellRate = rate?.sell?.spot || 0;
    const buyAndSellAvg = (buyRate + sellRate) / 2;

    setExchangeRate(buyAndSellAvg);
    setBuyRate(buyRate);
    setSellRate(sellRate);
    setLastUpdated(`${data.publishTime.date} ${data.publishTime.time}`);
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const handleExchange = () => {
    handleInit();
    if (exchangeRate) {
      const result =
        fromCurrency === "TWD"
          ? `${amount.toFixed(2)} 台幣 ≈ ${(amount / exchangeRate).toFixed(
              2
            )} 歐元`
          : `${amount.toFixed(2)} 歐元 ≈ ${(amount * exchangeRate).toFixed(
              2
            )} 台幣`;

      setResult(result);
    } else {
      alert("請先取得匯率！");
    }
  };

  return (
    <div>
      <div className="header">
        <h1>匯率計算工具</h1>
      </div>
      <div className="content">
        <p>查詢今日最新的台幣 (NTD) 兌換歐元 (EUR) 匯率。</p>
        <p>您可以輸入台幣或歐元的金額，立即獲取相對應的換算結果。</p>
        <div className="exchange-form">
          <input
            type="number"
            step="0.01"
            value={amount}
            onFocus={() => setAmount(0)}
            onChange={(e) =>
              setAmount(Number(e.target.value.replace(/^0+/, "")))
            }
            placeholder="輸入金額"
          />
          <select
            value={fromCurrency}
            onChange={(e) => setFromCurrency(e.target.value)}
          >
            <option value="TWD">台幣 (TWD)</option>
            <option value="EUR">歐元 (EUR)</option>
          </select>
          <button
            className="button"
            onClick={handleExchange}
            disabled={!exchangeRate}
          >
            進行兌換
          </button>
        </div>
        {exchangeRate ? (
          <div className="result">
            <p>買入匯率: {buyRate}</p>
            <p>賣出匯率: {sellRate}</p>
            <p>買賣平均價格: {exchangeRate.toFixed(2)}</p>
            <p>資料最後更新時間: {lastUpdated}</p>
            <p>
              資料來源:{" "}
              <a href="https://rate.bot.com.tw/xrt?Lang=zh-TW">
                臺灣銀行牌告匯率
              </a>
            </p>

            {result && (
              <div>
                <h3>換算結果：</h3>
                <p>{result}</p>
              </div>
            )}
          </div>
        ) : (
          <div className="loading">
            <p>正在取得匯率...</p>
          </div>
        )}
      </div>
      <div>
        {displayForm && (
          <FormComponent
            title={"請填寫您的資訊以獲取更多小工具"}
            ref={formRef}
          />
        )}
      </div>
    </div>
  );
};

export default CurrencyExchangeTool;
