import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

// 您的 Firebase 配置
const firebaseConfig = {
  apiKey: "AIzaSyAk_WcaBgORT-vBpTHEBZ7QlAJDY9fnEX0",
  authDomain: "zodiacinsightsexplorer.firebaseapp.com",
  projectId: "zodiacinsightsexplorer",
  storageBucket: "zodiacinsightsexplorer.appspot.com",
  messagingSenderId: "327435552296",
  appId: "1:327435552296:web:2a627118302822d4e2171d",
};

// 初始化 Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// 連接到 Firestore 模擬器
if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(db, "localhost", 8080);
}

export { db };
